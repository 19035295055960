import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    addressId: String,
    latitudeId: String,
    longitudeId: String,
  }

  connect() {
    console.log("Hello, Stimulus!", this.element);
    if (typeof google === "undefined" || typeof google.maps === "undefined") {
      window.addEventListener('googleMapsLoaded', this.initializeMapSearch.bind(this));
    } else {
      this.initializeMapSearch();
    }
  }

  initializeMapSearch() {
    let searchBox = new google.maps.places.SearchBox(this.element);
    this.loadInputValues(searchBox);
  }

  loadInputValues(searchBox) {
    searchBox.addListener('places_changed', () => {
      let place = searchBox.getPlaces()[0];
      let addressInput = document.getElementById(this.addressIdValue);
      if (addressInput != null){
        addressInput.value = place.formatted_address
      }
      let latitudeInput = document.getElementById(this.latitudeIdValue);
      if (latitudeInput != null){
        latitudeInput.value = place.geometry.location.lat()
      }
      let longitudeInput = document.getElementById(this.longitudeIdValue);
      if (longitudeInput != null){
        longitudeInput.value = place.geometry.location.lng()
      }

      if (typeof(place) === 'object') {
        let fullAddr = place.formatted_address.split(", ");
        this.setAllAddress(fullAddr, false, place.address_components);
      }

      const placeChangedEvent = new CustomEvent("changed_location_map", {
        detail: { place: place }
      });
      this.element.dispatchEvent(placeChangedEvent);
    });
  }

  setAllAddress(addr, withAddressComp, addressComponents) {
    let place_length = Object.keys(addr).length;
    const reversedKeys = Object.keys(addr).reverse();
    let index = 0;
    for (const key of reversedKeys) {
      const value = addr[key];
      if (withAddressComp) {
        if (value.long_name == "Chile" &&  value.short_name == "CL") {
          index = key;
          break;
        }
      } else {
        if (value == "Chile") {
          index = key;
          break;
        }
      }
    }
    if (index == 0) {
      return;
    }
    let idx = index-1;
    let region="";
    let country="";
    let commune="";
    if (withAddressComp) {
      region = addr[idx].long_name
      country = addr[index].long_name
      commune = addr[index-2].long_name
    }else{
      region = addr[idx]
      country = addr[index]
      commune = addr[index-2]
    }
    if (typeof region === "string" && region.length === 0) {
      return;
    }

    if (addressComponents!== undefined) {
      let addrCompLast = addressComponents[addressComponents.length - 1];
      let isNum = /^\d+$/.test(addrCompLast.long_name);
      if (isNum) {
        commune = commune.replace(addrCompLast.long_name+" ", "");
      }
    }
    
    const regions_arr = [
      {id: 1,name: 'Tarapacá'},
      {id: 2,name: 'Antofagasta'},
      {id: 3,name: 'Atacama'},
      {id: 4,name: 'Coquimbo'},
      {id: 5,name: 'Valparaíso'},
      {id: 6,name: "O'Higgins"},
      {id: 7,name: 'Maule'},
      {id: 8,name: 'Bío Bío'},
      {id: 9,name: 'Araucanía'},
      {id: 10,name: 'Los Lagos'},
      {id: 11,name: 'Aysén'},
      {id: 12,name: 'Magallanes y la Antártica Chilena'},
      {id: 13,name: 'Región Metropolitana'},
      {id: 14,name: 'Los Ríos'},
      {id: 15,name: 'Arica y Parinacota'},
      {id: 16,name: 'Ñuble'},
    ]

    const result = regions_arr.find(obj => obj.name === region);
    if (result === undefined) {
      return;
    }
    let region_id= result.id;
    //region_id
    
    let regionIdInput = document.getElementById("region_id");
    if (regionIdInput != null){
      regionIdInput.value = region_id;
    }

    let communeInput = document.getElementById("commune");
    if (communeInput != null) {
      communeInput.value = commune;
    }
    let communeInputReal = document.getElementById("real_commune");
    if (communeInputReal != null) {
      communeInputReal.value = commune;
    }
    console.log("******", {region_id,country, commune});
  }
}