import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    console.log("Hello, Stimulus!", this.element);
    this.setEyePosition()
  }

  static targets = ['eyeIcon']

  requestPermission() {
    Notification.requestPermission().then(function (permission) {
      if (permission === 'granted') {
        new Notification("Aprobado");
      }
    });
  }

  setEyePosition(){
    let position = document.getElementById('password').getBoundingClientRect()
    console.log('position', position.top)
    let top = 'top: ' + (position.top - 45) + 'px'
    document.getElementById('eyeIcon').style.cssText = top
    console.log(document.getElementById('eyeIcon').style.cssText)
  }


  showPassword() {
    this.password = document.querySelector('#password')

    if (password.type == 'text'){
      this.eyeIconTarget.classList.remove('bi-eye-slash-fill')
      this.eyeIconTarget.classList.add('bi-eye-fill')
      password.type = 'password'
      return
    }
    if (password.type == 'password'){
      this.eyeIconTarget.classList.remove('bi-eye-fill')
      this.eyeIconTarget.classList.add('bi-eye-slash-fill')
      password.type = 'text'
      return
    }
  }

  setErrors() {
    this.password = document.querySelector('#passwordError').classList.remove('d-none')
  }

}
